@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--marketplaceColorLightBg);

  /* sticky result summary in mobile */
  position: relative;
  top: 0;
  z-index: 1;
}

.searchResultSummary {
  composes: h3 from global;
  margin-top: 6px;

  display: flex;
  justify-content: space-between;

  background-color: var(--marketplaceColorLightBg);

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
}

.rightSideFilters {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & .filterBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 46px;
    min-height: 35px;

    & input {
      width: auto;
      margin-right: 8px;
    }

    & label {
      cursor: pointer;
      font-size: 15px;
    }

    padding: 10px 20px;
    margin: 0 9px 0 0;

    transition: all var(--transitionStyleButton);
    cursor: pointer;

    /* Font */
    font-weight: var(--fontWeightBold);
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2px;

    /* Button text styles */
    /* Default button text is centered without decorations */
    text-align: center;
    text-decoration: none;

    /* Button borders */
    color: var(--colorTextDark);
    border: 1px solid var(--colorTextDark);
    border-radius: 100px;
    background-color: var(--colorText);

    &:focus,
    &:hover {
      outline: none;
      box-shadow: var(--boxShadowFilterButton);
      text-decoration: none;
      border-color: var(--colorGrey300);
    }

    &:disabled {
      box-shadow: none;
      cursor: not-allowed;
    }

    @media (--viewportMedium) {
      height: auto;
      min-height: 0;
      padding: 10px 24px;
      margin: 0;
      font-size: 15px;
      line-height: 20px;

      &:hover,
      &:focus {
        border-color: transparent;
      }
    }

    & > svg {
      margin-right: 10px;
      fill: none;
    }
  }

  & .sortyByWrapper {
    display: inline-flex;
    margin: 0;

    & > div {
      margin-right: 0;

      & > button {
        margin-right: 0;
      }
    }
  }
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.modalContainer {
  width: 100%;
  padding: 94px 0;
  margin: 0 24px;
}

.modalHeadingWrapper {
  padding-bottom: 31px;
  border-bottom: 1px solid var(--colorGrey100);
}

.modalHeading {
  composes: h1 from global;
  margin-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  float: right;
  margin: 8px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 220px;
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--colorText);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
}
