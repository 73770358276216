.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.filterBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 46px;
  min-height: 35px;
  cursor: pointer;

  & input {
    width: auto;
    position: relative;
    bottom: 2px;
    margin-right: 8px;
    cursor: pointer;
  }

  & label {
    cursor: pointer;
    font-size: 15px;
    margin-top: 2px;
  }

  padding: 10px 20px;
  margin: 0 9px 0 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border: 1px solid var(--colorTextDark);
  border-radius: 100px;
  background-color: var(--colorText);

  &:focus,
  &:hover {
    outline: none;
    box-shadow: var(--boxShadowFilterButton);
    text-decoration: none;
    border-color: var(--colorGrey300);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    height: auto;
    min-height: 0;
    padding: 10px 24px;
    margin: 0;
    font-size: 15px;
    line-height: 20px;

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
  & > svg {
    margin-right: 10px;
    fill: none;
  }
}

.searchOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > h1 {
    margin: 0;
    padding: 0;
    font-size: 35px;
    line-height: 110%;
    font-weight: var(--fontWeightBlack);
    color: var(--colorText);
  }
}
.rightSideFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
