@import '../../../styles/customMediaQueries.css';

.root {
}

.icon {
  position: relative;
  transition: all 0.2s ease-out;
}

.iconArrowAnimation {
  transform: rotate(-180deg);
}

.menuContent {
  margin-top: 7px;
  padding-top: 24px;
  padding-bottom: 24px;
  min-width: 300px;
  border-radius: var(--borderRadiusMedium);
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--colorBlack);
}

.menuHeading {
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey700);
  margin-top: 24px;
  margin-left: 30px;
  margin-right: 30px;
}

.menuItem {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorBlack);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  &:disabled {
    color: var(--colorGrey300);
    cursor: default;
  }
  &:disabled:hover .menuItemBorder {
    width: 0;
  }
}

.clearMenuItem {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--colorGrey700);
    transition: width var(--transitionStyleButton);
  }
}
